<template>
  <footer class="footer">
    <div class="content">
      <button
        class="footer__politics-btn btn-border_b"
        @click="openPoliticsModal"
      >Политика конфиденциальности</button>
      <p class="footer__copyright">&#169; 2012-2022 De Club</p>
      <p class="footer__info">Сайт не является публичной офертой и носит информационный характер</p>
    </div>
  </footer>
</template>

<script>
import { POLITICS_MODAL } from '@/store/modalConstants';

export default {
  name: 'TheFooter',
  methods: {
    openPoliticsModal() {
      this.$store.commit('modal/openModal', {
        modal: POLITICS_MODAL,
        label: 'open_politics',
        analytics: false,
      });
    },
  },
};
</script>

<style lang="scss">
@import './footer.scss';
</style>
