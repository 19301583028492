import axios from 'axios';
import modules from './blocks';

export default {
  actions: {
    init({ commit, dispatch, state }, { route }) {
      const url = `https://de-club.ru/cms/wp-json/dc/v2/${route}`;

      axios.get(url).then((res) => {
        commit('settings/setData', res.data.settings);
        commit('header/setData', res.data.menu);
        commit('firstScreen/setData', {
          data: res.data.pageData.fs,
          images: res.data.settings.carousel_fs,
        });
        commit('benefits/setData', res.data.pageData.benefits);
        dispatch('portfolio/initData', {
          data: res.data.pageData.portfolio,
          portfolioList: res.data.portfolio_list,
        });
        dispatch('afterbefore/initData', res.data.pageData.ab);
        commit('process/setData', {
          data: res.data.pageData.process,
          process: res.data.process_list,
        });
        commit('team/setData', {
          data: res.data.pageData.team,
          team: res.data.team_list,
        });
        commit('acquaintance/setData', res.data.pageData.acquaintance);
        commit('company/setData', res.data.pageData.company);
        commit('education/setData', res.data.pageData.education);
        dispatch('tariff/initData', {
          data: res.data.pageData.tariff,
          content: res.data.tariffs_list,
        });
        commit('trends/setData', res.data.pageData.trends);
        commit('media/setData', res.data.pageData.publications);
        commit('contacts/setData', res.data.pageData.contacts);
      }).then(() => {
        commit('setDataLoaded');
      });

      /* GET params parse */
      const locationSearch = window.location.search;
      if (locationSearch) {
        const params = locationSearch.substring(1);
        state.GETParams = params.split('&').map((param) => {
          const arr = param.split('=');
          return {
            name: arr[0],
            value: arr[1],
          };
        });
      }
    },
  },
  state: {
    dataLoaded: false,
    GETParams: [],
  },
  mutations: {
    setDataLoaded(state) {
      state.dataLoaded = true;
    },
  },
  modules: {
    ...modules,
  },
};
