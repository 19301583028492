<template>
    <div class="calculator">
        <p class="calculator__title">Рассчитайте стоимость проекта</p>
        <div class="calculator__body">
            <div class="calculator__item calculator__item_area">
                <div class="calculator__wrapper d-flex align-items-center">
                    <label class="calculator__legend" for="fs-calc-area">Площадь объекта</label>
                    <input
                        type="number"
                        id="fs-calc-area"
                        v-model="square"
                        :min="areaMin"
                        :max="areaMax"
                        @change="triggerAnalytics"
                    >&nbsp;<span>м<sup>2</sup></span>
                </div>
                <div class="calculator__wrapper">
                    <!-- <input
                        type="range"
                        id="fs-calc-range"
                        class="calculator__range"
                        v-model="square"
                        :min="areaMin"
                        :max="areaMax"
                        :style="{ background: rangeStyle }"
                        @blur="triggerAnalytics"
                    > -->
                    <UiCustomRange
                      :min="areaMin"
                      :max="areaMax"
                      :value="square"
                      @setSquare="setSquare"
                    />
                    <!-- <vue3-slider
                        v-model="square"
                        :min="areaMin"
                        :max="areaMax"
                    /> -->
                </div>
            </div>
            <div class="calculator__item calculator__item_object">
                <p class="calculator__legend">Тип объекта</p>
                <div class="calculator__wrapper">
                    <div class="calculator__radio">
                        <input
                            type="radio"
                            name="fs-calc-objects"
                            id="fs-apartment"
                            value="flat"
                            v-model="type"
                        >
                        <label for="fs-apartment">Квартира</label>
                    </div>
                    <div class="calculator__radio">
                        <input
                            type="radio"
                            name="fs-calc-objects"
                            id="fs-house"
                            value="house"
                            v-model="type"
                        >
                        <label for="fs-house">Загородный дом</label>
                    </div>
                    <div class="calculator__radio">
                        <input
                            type="radio"
                            name="fs-calc-objects"
                            id="fs-office"
                            value="office"
                            v-model="type"
                        >
                        <label for="fs-office">Офис</label>
                    </div>
                    <div class="calculator__radio">
                        <input
                            type="radio"
                            name="fs-calc-objects"
                            id="fs-townhouse"
                            value="townhouse"
                            v-model="type"
                        >
                        <label for="fs-townhouse">Таунхаус</label>
                    </div>
                </div>
            </div>
            <div class="calculator__item calculator__item__repair d-flex align-items-center">
                <p class="calculator__legend">Нужен ремонт?</p>
                <div class="calculator__wrapper">
                    <input
                        type="checkbox"
                        id="fs-calc-repair"
                        class="d-none"
                        true-value="2"
                        false-value="1"
                        v-model="work"
                    >
                    <label for="fs-calc-repair"></label>
                </div>
            </div>
            <div class="calculator__item calculator__item_ratebar">
                <p class="calculator__legend">Время создания проекта</p>
                <div class="calculator__wrapper calculator__ratebar">
                    <p
                        class="calculator__ratebar-item calculator__ratebar-item_you"
                        :style="{'min-width': 16+20/50*you_val+'%'}"
                    >Вы - <span>{{ you_val }}</span>ч</p>
                    <p
                        class="calculator__ratebar-item calculator__ratebar-item_we"
                        :style="{'min-width': 24+50/1450*we_val+'%'}"
                    >Мы - <span>{{ we_val }}</span>ч</p>
                </div>
            </div>
        </div>
        <button
            class="fs-calc-btn btn"
            @click="openCalcModal"
        >Узнать стоимость</button>
    </div>
</template>

<script>
import sendAnalytics from '@/sendAnalytics';
import { CALC_MODAL } from '@/store/modalConstants';
import { mapState } from 'vuex';

import UiCustomRange from '@/elements/UiCustomRange/UiCustomRange.vue';

export default {
  name: 'BlockCalculator',

  components: {
    UiCustomRange,
  },

  data() {
    return {
      areaMin: 20,
      areaMax: 800,
    };
  },

  computed: {
    ...mapState('calculator', ['you_val', 'we_val']),

    square: {
      get() {
        return this.$store.state.calculator.square;
      },

      set(value) {
        let val = value;
        if (val < this.areaMin || Number.isNaN(val)) val = this.areaMin;
        if (val > this.areaMax) val = this.areaMax;
        this.$store.dispatch('calculator/setCalcSquare', val);
      },
    },

    type: {
      get() {
        return this.$store.state.calculator.type;
      },
      set(value) {
        this.$store.dispatch('calculator/setCalcType', value);
        this.triggerAnalytics();
      },
    },

    work: {
      get() {
        return this.$store.state.calculator.work;
      },

      set(value) {
        this.$store.dispatch('calculator/setCalcWork', value);
        this.triggerAnalytics();
      },
    },

    rangeStyle() {
      const percent = (Number(this.square) / Number(this.areaMax)) * 100;
      return `linear-gradient(to right, rgb(211, 162, 100) 0%, rgb(211, 162, 100) ${percent}%, rgb(171, 171, 171) ${percent}%, rgb(171, 171, 171) 100%)`;
    },
  },

  mounted() {
    this.$store.commit('calculator/recalculate');
  },

  methods: {
    openCalcModal() {
      this.$store.commit('modal/openModal', {
        modal: CALC_MODAL,
        label: 'kalkulator',
        analytics: true,
      });
    },

    triggerAnalytics() {
      sendAnalytics('navigation', 'click', 'kalkulator_vzaimodeisvie');
    },

    setSquare(val) {
      this.square = val;
    },
  },
};
</script>

<style lang="scss">
@import './calculator.scss';
</style>
