<template>
  <section class="fs">
    <div class="fs__bg">
      <div class="fs__background fs__background_gradient"></div>
      <div class="fs__background fs__background_image">
        <div
          v-if="images"
          class="swiper"
          ref="carousel"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(image, index) in images"
              :key="index"
            >
              <picture class="fs__picture">
                <source :srcset="image.image_desktop" media="(min-width: 1200px)">
                <source :srcset="image.image_tablet" media="(min-width: 768px)">
                <source :srcset="image.image_mobile" media="(min-width: 320px)">
                <img :src="image.image_desktop" alt="" loading="lazy" @load="onImageLoad">
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <article class="fs__content">
        <div class="fs__header">
          <h1 class="fs__title">{{ title }}</h1>
          <p class="fs__subtitle" v-html="subtitle"></p>
        </div>
      </article>
      <div class="fs__calc">
        <BlockCalculator />
      </div>
    </div>
  </section>
</template>

<script>
import BlockCalculator from '@/components/BlockCalculator/BlockCalculator.vue';
import { mapState } from 'vuex';
import Swiper, { Autoplay, EffectFade } from 'swiper';

export default {
  name: 'BlockFirstScreen',

  components: {
    BlockCalculator,
  },

  data() {
    return {
      imagesLoad: false,
    };
  },

  computed: {
    ...mapState('firstScreen', ['title', 'subtitle', 'images']),
  },

  methods: {
    onImageLoad() {
      if (!this.imagesLoad) {
        this.imagesLoad = true;
        this.swiperInit();
      }
    },
    swiperInit() {
      this.swiper = new Swiper(this.$refs.carousel, {
        modules: [Autoplay, EffectFade],
        effect: 'fade',
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          waitForTransition: false,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./firstScreen.scss";
</style>
