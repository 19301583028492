export default {
  namespaced: true,
  state: {
    title: '',
    text: '',
    textDesk: '',
    imgMob: '',
    imgDesk: '',
  },
  mutations: {
    setData(state, data) {
      state.title = data.title;
      state.text = data.text;
      state.textDesk = data.text_desk;
      state.imgMob = data.image_mob;
      state.imgDesk = data.image_desk;
    },
  },
};
