<template>
  <div class="tablist" v-if="tabType === 'category'">
      <div class="tablist__wrapper">
          <p class="tablist__title">Стиль:</p>
          <ul class="tablist__list">
              <li
                class="tablist__item"
                v-for="(item, index) in itemList"
                :key="index"
                :class="{active: activeCat === index}"
                @click="$emit('setActive', index)"
              >{{item.name}}</li>
          </ul>
      </div>
  </div>

  <div class="tablist tablist_object" v-if="tabType === 'object'">
    <div class="tablist__wrapper">
      <p class="tablist__title">Объект:</p>
      <ul class="tablist__list">
        <li
          class="tablist__item"
          v-for="(item, index) in itemList"
          :key="index"
          :class="{active: activeObject === item.itemId}"
          @click="$emit('setActive', item.itemId)"
        >
          {{item.area}}  <span>м<small><sup>2</sup></small></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTabList',
  props: {
    tabType: String,
    itemList: Array,
    activeCat: Number,
    activeObject: Number,
  },
  emits: ['setActive'],
};
</script>

<style lang="scss">
@import './tablist.scss';
</style>
