import header from './header';
import benefits from './benefits';
import firstScreen from './firstScreen';
import portfolio from './portfolio';
import afterbefore from './afterbefore';
import process from './process';
import team from './team';
import acquaintance from './acquaintance';
import company from './company';
import education from './education';
import tariff from './tariff';
import trends from './trends';
import media from './media';
import contacts from './contacts';

export default {
  header,
  benefits,
  firstScreen,
  portfolio,
  afterbefore,
  process,
  team,
  acquaintance,
  company,
  education,
  tariff,
  trends,
  media,
  contacts,
};
