<template>
  <section class="company">
    <div class="content">
      <h2 class="section-title">{{title}}</h2>
      <div class="swiper" ref="slider">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in logos"
            :key="index"
            class="swiper-slide"
          >
            <div class="company__item">
              <VLazyImage :src="item" :alt="`Логотип компании ${index + 1}`" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Swiper from 'swiper';
import VLazyImage from 'v-lazy-image';

export default {
  name: 'BlockCompany',
  components: {
    VLazyImage,
  },
  computed: {
    ...mapState('company', ['title', 'logos']),
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.slider, {
      slidesPerView: 'auto',
      enabled: false,
      breakpoints: {
        600: {
          spaceBetween: 20,
          enabled: true,
        },
      },
    });
  },
  beforeUnmount() {
    this.swiper.destroy(true, true);
  },
};
</script>

<style lang="scss">
@import './company.scss';
</style>
