<template>
  <div
    class="preloader"
    :style="{'width': width, 'height': height}"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#d3a264"
        stroke-width="6"
        r="32"
        stroke-dasharray="150.79644737231007 52.26548245743669"
        transform="matrix(1,0,0,1,0,0)"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>

<style lang="scss">
  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: 100%;
      height: 100%;
      animation: preloader_rotate 1s linear 0s infinite;
    }
  }

  @keyframes preloader_rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
