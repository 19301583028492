<template>
    <div class="modal-frame" v-if="isOpen">
        <div
            class="modal-frame__backdrop"
            @click="closeModal"
        ></div>

        <div
            class="frame-youtube"
            v-if="isYoutube"
        >
            <button
                class="modal-frame__close-btn frame-youtube__close-btn"
                @click="closeModal"
            >
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.84155" y="0.628399" width="18.6986" height="1.62596" transform="rotate(45 1.84155 0.628399)" fill="#ABABAB"/>
                    <rect x="1.02856" y="13.6361" width="18.6986" height="1.62596" transform="rotate(-45 1.02856 13.6361)" fill="#ABABAB"/>
                </svg>
            </button>
            <div class="frame-youtube__wrapper">
                <img
                    class="frame-youtube__placeholder"
                    :src="require('@/assets/img/video-placeholder.jpg')"
                    alt=""
                >
                <div
                    class="frame-youtube__video"
                >
                    <iframe
                        title=""
                        width="640"
                        height="360"
                        :src="`https://www.youtube.com/embed/${openFrame}?feature=oembed`"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </div>

        <div
            class="frame-tour"
            v-if="isTour"
        >
            <div class="frame-tour__wrapper">
                <iframe
                    title=""
                    :src="openFrame"
                    frameborder="0"
                    allow="autoplay"
                    allowfullscreen="allowfullscreen"
                ></iframe>
            </div>
            <button
                class="modal-frame__close-btn frame-tour__close-btn"
                @click="closeModal"
            >
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.84155" y="0.628399" width="18.6986" height="1.62596" transform="rotate(45 1.84155 0.628399)" fill="#ABABAB"/>
                    <rect x="1.02856" y="13.6361" width="18.6986" height="1.62596" transform="rotate(-45 1.02856 13.6361)" fill="#ABABAB"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import { YOUTUBE_MODAL, TOUR_MODAL } from '@/store/modalConstants';
import { mapState } from 'vuex';

export default {
  name: 'TheModalFrame',
  computed: {
    ...mapState('modalFrame', ['isOpen', 'openFrame', 'label']),
    isTour() {
      return TOUR_MODAL === this.label;
    },
    isYoutube() {
      return YOUTUBE_MODAL === this.label;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('modalFrame/closeModal');
    },
  },
};
</script>

<style lang="scss">
@import './modal-frame.scss';
</style>
