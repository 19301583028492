export default {
  namespaced: true,
  state: {
    title: '',
    categories: [],
    portfolio: {},
    objectList: [],
    activeCat: 0,
    activeObject: 0,
    currentObject: {},
  },
  mutations: {
    setData(state, payload) {
      state.title = payload.data.title;
      state.categories = payload.data.category;
      state.portfolio = payload.portfolioList;
    },

    setActiveCategory(state, id) {
      state.activeCat = id;
      state.objectList = [];
      state.categories[id].items.forEach((pId, index) => {
        if (index === 0) state.activeObject = pId;
        state.objectList.push({
          itemId: pId,
          area: state.portfolio[pId].portfolio_area,
        });
      });
      state.currentObject = state.portfolio[state.activeObject];
    },

    setActiveObject(state, pid) {
      state.activeObject = pid;
      state.currentObject = state.portfolio[pid];
    },
  },
  actions: {
    initData({ commit }, payload) {
      commit('setData', payload);
      commit('setActiveCategory', 0);
    },
  },
};
