<template>
    <section class="team">
        <div class="content">
            <h2
                class="section-title section-title_large"
                id="team"
                v-html="title"
            ></h2>
            <div class="swiper team__carousel" ref="carousel">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide team__item"
                        v-for="(item, index) in team"
                        :key="index"
                    >
                        <VLazyImage
                            class="team__photo"
                            :src="item.team_photo"
                            :alt="item.team_name"
                        />
                        <p class="team__name">{{item.team_name}}</p>
                        <p class="team__text team__text_position">{{item.team_position}}</p>
                        <p class="team__text team__text_practice">{{item.team_practice}}</p>
                        <div class="team__text team__text_internship" v-if="item.team_intership.length">
                            <p>Международные стажировки </p>
                            <div class="team__flags">
                                <img
                                    v-for="(img, id) in item.team_intership"
                                    :key="id"
                                    :src="img"
                                    alt=""
                                    loading="lazy"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VLazyImage from 'v-lazy-image';
import Swiper from 'swiper';
import { mapState } from 'vuex';

export default {
  name: 'BlockTeam',
  components: {
    VLazyImage,
  },
  computed: {
    ...mapState('team', ['title', 'team']),
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.carousel, {
      slidesPerView: 'auto',
      spaceBetween: 20,
      breakpoints: {
        1024: {
          spaceBetween: 30,
        },
      },
    });
  },
  beforeUnmount() {
    this.swiper.destroy(true, true);
  },
};
</script>

<style lang="scss">
@import './team.scss';
</style>
