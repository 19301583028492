import sendAnalytics from '@/sendAnalytics';

export default {
  namespaced: true,
  state: {
    isOpen: false,
    openFrame: '',
    label: '',
  },
  mutations: {
    openFrame(state, payload) {
      state.isOpen = true;
      state.openFrame = payload.frame;
      state.label = payload.label;
      document.body.classList.add('modal-open');
      if (payload.analytics) {
        sendAnalytics('navigation', 'click', payload.name);
      }
    },
    closeModal(state) {
      state.isOpen = false;
      state.openFrame = '';
      state.label = '';
      document.body.classList.remove('modal-open');
    },
  },
};
