<template>
  <section class="trends">
    <div class="content">
      <h2
        class="section-title section-title_large"
        v-html="title"
      ></h2>
      <UiSectionCards
        :slides="content"
        theme="dark"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import UiSectionCards from '@/elements/UiSectionCards/UiSectionCards.vue';

export default {
  name: 'BlockTrends',
  components: {
    UiSectionCards,
  },
  computed: {
    ...mapState('trends', ['title', 'content']),
  },
};
</script>

<style lang="scss">
@import './trends.scss';
</style>
