import sendAnalytics from '@/sendAnalytics';

const labels = {
  0: 'kollazhnyj',
  1: 'optimalnyj',
  2: 'eksklyuzivnyj',
};

export default {
  namespaced: true,
  state: {
    title: undefined,
    content: undefined,
    activeIndex: 0,
    tariff_name: undefined,
    tariff_price: undefined,
    tariff_included: undefined,
    tariff_feat: undefined,
    tariff_pic_desk: undefined,
    // tariff_pic_mob: undefined,
  },
  mutations: {
    setData(state, payload) {
      state.title = payload.data.title;
      state.content = payload.data.items.map((id) => payload.content[id]);
    },
    setActiveItem(state, data) {
      state.activeIndex = data.index;

      const content = state.content[data.index];

      state.tariff_name = content.tariff_name;
      state.tariff_price = content.tariff_price;
      state.tariff_included = content.tariff_included;
      state.tariff_feat = content.tariff_feat;
      state.tariff_pic_desk = content.tariff_pic_desk;
      // state.tariff_pic_mob = content.tariff_pic_mob;
      if (data.analytics) {
        sendAnalytics('navigation', 'click', labels[data.index]);
      }
    },
  },
  actions: {
    initData({ commit }, payload) {
      commit('setData', payload);
      commit('setActiveItem', {
        index: 1,
        analytics: false,
      });
    },
  },
};
