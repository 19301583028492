export default function sendAnalyticsData(category, action, label = false, metrika = false) {
  if (window.ga && category && action) {
    window.ga('send', 'event', category, action, label);
  }

  if (window.ym && metrika) {
    console.log('send ym metrika:', metrika);
    window.ym(49038584, 'reachGoal', metrika);
  }

  console.log({
    category,
    action,
    label,
    metrika,
    ga: !!(window.ga && category && action),
    ym: window.ym && metrika,
  });

  if (action === 'send' && window.fbq) {
    window.fbq('track', 'Lead');
  }

  if (action === 'send' && window.VK) {
    console.log('vk goal');
    window.VK.Goal('lead');
    window.VK.Goal('conversion');
  }
}
