export default {
  namespaced: true,
  state: {
    title: '',
    content: [],
  },
  mutations: {
    setData(state, data) {
      state.title = data.title;
      state.content = data.content;
    },
  },
};
