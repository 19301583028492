<template>
    <section class="tariff">
        <div class="content">
            <h2
                class="section-title section-title_tariff"
                id="pricing"
            >{{title}}</h2>
            <ul class="tariff__tabs">
                <li
                    :class="['tariff__tab', { 'tariff__tab_active': index === activeIndex }]"
                    v-for="(item, index) in content"
                    :key="index"
                    @click="setActiveItem(index)"
                >{{ item.tariff_name }}</li>
            </ul>
            <div class="tariff__body">
                <picture class="tariff__picture">
                    <VLazyImage
                        v-if="tariff_pic_desk"
                        :src="tariff_pic_desk"
                        :alt="tariff_name"
                    />
                </picture>
                <div class="tariff__wrapper">
                    <div class="tariff__content tariff__content_top">
                        <article class="tariff__article tariff__article_included">
                            <p class="tariff__title">Входит в проект</p>
                            <div
                                class="tariff__list tariff__list_included"
                                v-html="tariff_included"
                            ></div>
                        </article>
                        <article class="tariff__article tariff__article_feat">
                            <p class="tariff__title">Особенности проекта</p>
                            <div
                                class="tariff__list tariff__list_feat"
                                v-html="tariff_feat"
                            ></div>
                        </article>
                    </div>
                    <div class="tariff__content tariff__content_bottom">
                        <p class="tariff__price">
                            от <span>{{tariff_price}}</span> руб / м<sup>2</sup>
                        </p>
                        <button
                            class="tariff__btn btn"
                            @click="openCallbackModal"
                        >Рассчитать стоимость</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VLazyImage from 'v-lazy-image';
import { CALLBACK_MODAL } from '@/store/modalConstants';
import { mapState } from 'vuex';

export default {
  name: 'BlockTariff',
  components: {
    VLazyImage,
  },
  computed: {
    ...mapState('tariff', [
      'title',
      'content',
      'activeIndex',
      'tariff_name',
      'tariff_price',
      'tariff_included',
      'tariff_feat',
      'tariff_pic_desk',
    ]),
  },
  methods: {
    setActiveItem(index) {
      this.$store.commit('tariff/setActiveItem', {
        index,
        analytics: true,
      });
    },
    openCallbackModal() {
      this.$store.commit('modal/openModal', {
        modal: CALLBACK_MODAL,
        label: 'tarifi_raschitat',
        analytics: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import './tariff.scss';
</style>
