<template>
  <div
    :class="['form-input', `form-input_${theme}`]"
  >
    <label
      :for="inputId"
      class="form-input__label"
    >Ваше имя</label>
    <input
      :id="inputId"
      type="text"
      name="ima_kl"
      class="form-input__input"
      :placeholder="placeholder"
      v-model="inputValue"
    >
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'light',
      validator: (val) => ['light', 'dark'].indexOf(val) !== -1,
    },
    inputId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Имя',
    },
  },
  emits: ['onChange'],
  data() {
    return {
      inputValue: '',
    };
  },
  watch: {
    inputValue() {
      this.$emit('onChange', this.inputValue);
    },
  },
};
</script>

<style lang="scss">
@import 'form-input.scss';
</style>
