export default {
  // namespaced: true,
  state: {
    windowWidth: 0,
  },
  mutations: {
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
  },
  actions: {
    changeWidth({ commit }, newWidth) {
      commit('setWindowWidth', newWidth);
    },
  },
};
