import sendAnalytics from '@/sendAnalytics';

export default {
  namespaced: true,
  state: {
    isOpen: false,
    openModal: undefined,
    meta: undefined,
  },
  mutations: {
    openModal(state, payload) {
      state.openModal = payload.modal;
      state.isOpen = true;
      state.meta = payload.label;
      document.body.classList.add('modal-open');
      if (payload.analytics) {
        sendAnalytics('lead_form', 'click', payload.label);
      }
    },
    closeModal(state) {
      state.isOpen = false;
      state.openModal = undefined;
      state.meta = undefined;
      document.body.classList.remove('modal-open');
    },
  },
};
