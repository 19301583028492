<template>
  <section class="contacts">
    <div class="content">
      <div class="contacts__content">
        <h2 class="section-title" id="contacts">
          <span>{{title}}</span>
        </h2>
        <ul class="contacts__list">
          <li class="contacts__item">
            <p class="contacts__title">Мы находимся</p>
            <p class="contacts__subtitle contacts__subtitle_address">
              <span>{{address}}</span>
              <a
                class="contacts__link btn-border_b"
                href="https://yandex.ru/maps/213/moscow/?from=mapframe&ll=37.584558%2C55.806989&mode=routes&rtext=~55.806929%2C37.584364&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D243506647621&source=mapframe&um=constructor%3Ada0783a948f8c5060df4c6a44ec48aae33c0cc54b01af21d54e364530d2a9800&utm_source=mapframe&z=19.53"
                target="_blank"
              >Построить маршрут</a>
            </p>
          </li>
          <li class="contacts__item">
            <p class="contacts__title">Метро</p>
            <p class="contacts__subtitle">{{station}}</p>
          </li>
          <li class="contacts__item">
            <p class="contacts__title">Телефон</p>
            <a
              :href="`tel:${phone.unmasked}`"
              class="contacts__subtitle contacts__subtitle_link"
            >{{phone.masked}}</a>
          </li>
          <li class="contacts__item">
            <p class="contacts__title">Предложения по сотрудничеству</p>
            <a
              href="mailto:hello@de-club.ru?subject=Предложение о сотрудничестве"
              class="contacts__subtitle contacts__subtitle_link"
              title='Укажите тему письма "Предложение о сотрудничестве"'
            >hello@de-club.ru</a>
          </li>
        </ul>
      </div>
      <picture class="contacts__pic">
        <source media="(min-width: 576px)" :srcset="picDesk">
        <VLazyImage
          :src="picMob"
          alt="Наши контакты"
        />
      </picture>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import VLazyImage from 'v-lazy-image';

export default {
  name: 'BlockContacts',
  components: {
    VLazyImage,
  },
  computed: {
    ...mapState('contacts', ['title', 'picMob', 'picDesk', 'address', 'station']),
    ...mapState('settings', ['phone']),
  },
};
</script>

<style lang="scss">
@import './contacts.scss';
</style>
