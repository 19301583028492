export default {
  namespaced: true,
  state: {
    phone: {
      masked: undefined,
      unmasked: undefined,
    },
  },
  mutations: {
    setData(state, data) {
      state.phone.masked = data.phone.masked;
      state.phone.unmasked = data.phone.unmasked;
    },
  },
};
