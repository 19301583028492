<template>
  <div class="swiper carousel" ref="carousel">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in slides"
        :key="index"
      >
        <picture class="carousel__picture">
          <source media="(min-width: 600px)" :srcset="item.full">
          <img
            v-if="index === 0"
            :src="item.mobile"
            @load="firstImgLoad"
            loading="lazy"
            alt=""
          >
          <img
            v-else
            :src="item.mobile"
            loading="lazy"
            alt=""
          >
        </picture>
      </div>
    </div>
    <div
      class="carousel__pagination"
      ref="pagination"
    ></div>
    <div class="swiper-button swiper-button-prev" ref="nav_prev">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.64645 9.35352L0.292894 4.99996L4.64645 0.646408L5.35355 1.35352L1.70711 4.99996L5.35355 8.64641L4.64645 9.35352Z"
          fill="#9E162F"
        />
      </svg>
    </div>
    <div class="swiper-button swiper-button-next" ref="nav_next">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.35356 0.646484L5.70711 5.00004L1.35356 9.35359L0.646454 8.64648L4.2929 5.00004L0.646454 1.35359L1.35356 0.646484Z"
          fill="#9E162F"
        />
      </svg>
    </div>
    <BlurLoadScreen v-if="loading"/>
  </div>
</template>

<script>
import Swiper, { Pagination, Navigation } from 'swiper';
import BlurLoadScreen from '@/elements/BlurLoadScreen/BlurLoadScreen.vue';

export default {
  name: 'BlockPortfolioSlider',

  props: {
    slides: Array,
    loading: Boolean,
  },

  emits: ['loaded'],

  components: {
    BlurLoadScreen,
  },

  methods: {
    updateSwiper() {
      this.$nextTick(() => {
        this.swiper.activeIndex = 0;
        this.swiper.update();
        this.$emit('loaded');
      });
    },

    firstImgLoad() {
      if (this.loading) {
        this.updateSwiper();
      }
    },
  },

  mounted() {
    this.swiper = new Swiper(this.$refs.carousel, {
      modules: [Pagination, Navigation],
      rewind: true,
      pagination: {
        el: this.$refs.pagination,
        type: 'fraction',
      },
      navigation: {
        nextEl: this.$refs.nav_next,
        prevEl: this.$refs.nav_prev,
      },
    });
  },

  beforeUnmount() {
    this.swiper.destroy(true, true);
  },
};
</script>
