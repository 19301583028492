export default {
  namespaced: true,
  state: {
    menu: '',
  },
  mutations: {
    setData(state, menu) {
      state.menu = menu;
    },
  },
};
