export default {
  namespaced: true,
  state: {
    title: undefined,
    content: undefined,
  },
  mutations: {
    setData(state, payload) {
      state.title = payload.data.title;
      state.content = payload.data.items.map((id) => payload.process[id]);
    },
  },
};
