<template>
  <section class="acquaintance">
    <div class="content">
      <div class="acquaintance__wrapper">
        <h2 class="section-title">{{title}}</h2>
        <picture class="acquaintance__pic">
          <source media="(min-width: 600px)" :srcset="imgDesk">
          <VLazyImage
            :src="imgMob"
            :alt="title"
          />
        </picture>
        <div class="acquaintance__content">
          <article
            class="acquaintance__text acquaintance__text_desk"
            v-html="textDesk"
          ></article>
          <article class="acquaintance__text" v-html="text"></article>
          <form
            action="/mail-new.php"
            class="acquaintance__form"
            @submit.prevent="onSubmit"
          >
            <p class="acquaintance__form-title">
              Оставьте контакты, мы перезвоним и уточним удобное время для встречи
            </p>
            <div class="acquaintance__inputs">
              <input type="hidden" name="site_form_id" :value="meta">

              <input
                v-for="{name, value}, key in GETParams"
                type="hidden"
                :key="key"
                :name="name"
                :value="value"
              >

              <UiInputPhone
                class="acquaintance__input"
                inputId="acquaintance_phone"
                theme="dark"
                @onChange="onChangePhone"
                @onValid="onValidationPhone"
                ref="inputPhone"
              />
              <UiFormButton
                title="Записаться"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import UiInputPhone from '@/elements/UiInputElems/UiInputPhone.vue';
import UiFormButton from '@/elements/UiFormButton/UiFormButton.vue';
import VLazyImage from 'v-lazy-image';
import { THANKS_MODAL } from '@/store/modalConstants';

import submit from '@/submitForm';
import sendAnalytics from '@/sendAnalytics';

export default {
  name: 'BlockAcquaintance',

  components: {
    UiInputPhone,
    UiFormButton,
    VLazyImage,
  },

  data() {
    return {
      valuePhone: '',
      phoneValid: false,
      meta: 'acquaintance_form',
    };
  },

  computed: {
    ...mapState('acquaintance', ['title', 'text', 'textDesk', 'imgMob', 'imgDesk']),
    GETParams() {
      return this.$store.state.data.GETParams;
    },
  },

  methods: {
    onChangePhone(val) {
      this.valuePhone = val;
    },

    onValidationPhone(bool) {
      this.phoneValid = bool;
    },

    onSubmit(event) {
      if (this.phoneValid) {
        submit(event).then((res) => {
          if (res) {
            this.$refs.inputPhone.clearInput();
            sendAnalytics('lead_form', 'send', this.meta, 'lead_form');
            this.$store.commit('modal/openModal', {
              modal: THANKS_MODAL,
              label: THANKS_MODAL,
              analytics: false,
            });
          }
        }).catch((err) => {
          console.log('error', err);
        });
      } else {
        this.$refs.inputPhone.onError();
      }
    },
  },
};
</script>

<style lang="scss">
@import './acquaintance.scss';
</style>
