<template>
  <section class="benefits">
    <div class="content">
      <div class="benefits__content">
        <h2
            class="section-title"
            id="approach"
            v-html="title"
        ></h2>
        <ul class="benefits__list">
            <li
              v-for="(item, index) in content"
              :key="index"
              class="benefits__item"
            >
              <p class="benefits__item-title">{{item.title}}</p>
              <p class="benefits__item-desc">{{item.desc}}</p>
            </li>
        </ul>
      </div>
      <div class="benefits__image">
        <img
          :src="images.full"
          alt="Наша команда"
          loading="lazy"
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BlockBenefits',
  computed: {
    ...mapState('benefits', ['title', 'content', 'images']),
  },
};
</script>

<style lang="scss">
@import './benefits.scss';
</style>
