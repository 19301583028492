/**
 * type: Тип объекта:
 * * flat:      квартира
 * * house:     загородный дом
 * * office:    офис
 * * townhouse: таунхаус
 * square: Площадь объекта
 * work: 1 - только дизайн, 2 - дизайн + ремонт
 */

export default {
  namespaced: true,
  state: {
    type: 'flat',
    work: '1',
    square: 150,
    you_val: 0,
    we_val: 0,
    // const
    weDefault: {
      flat: 370,
      office: 370,
      townhouse: 450,
      house: 510,
    },
    youDefault: {
      flat: 10,
      office: 10,
      townhouse: 15,
      house: 15,
    },
  },
  mutations: {
    updateCalcType(state, type) {
      state.type = type;
    },
    updateCalcWork(state, work) {
      state.work = work;
    },
    updateCalcSquare(state, square) {
      state.square = square;
    },
    recalculate(state) {
      const base = state.square > 300 ? Math.floor((state.square - 300) / 25) : 0;

      state.you_val = (state.youDefault[state.type] * Number(state.work)) + (base * 1);
      state.we_val = (state.weDefault[state.type] * Number(state.work)) + (base * 5);
    },
  },
  actions: {
    setCalcType({ commit }, value) {
      commit('updateCalcType', value);
      commit('recalculate');
    },
    setCalcWork({ commit }, value) {
      commit('updateCalcWork', value);
      commit('recalculate');
    },
    setCalcSquare({ commit }, value) {
      commit('updateCalcSquare', value);
      commit('recalculate');
    },
  },
};
