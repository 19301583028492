<template>
    <header :class="['header', {scrolled: isScroll}]">
        <div class="header__content">
            <a href="/" class="header__logo">
                <HeaderLogo
                    :isFull="!isScroll"
                    :isMobile="windowWidth < 1200"
                />
                <span class="sr-only">Главная страница</span>
            </a>
            <nav
                :class="['header__nav', {'header__nav_active': isShowNav}]"
            >
                <ul class="header__list">
                    <li
                        v-for="(item, index) in menu"
                        class="header__item"
                        :key="index"
                    ><a
                        href="#0"
                        @click.prevent="onClickNavItem(item.url)"
                    >{{ item.title }}</a></li>
                </ul>
                <div class="header__contacts">
                    <button
                        class="header__callback-btn btn-border_b"
                        @click="openCallbackModal"
                    >Позвоните мне</button>
                    <a :href="`tel:${phone.unmasked}`" class="header__phone">{{ phone.masked }}</a>
                    <p class="header__subtext">Сайт не является публичной офертой и носит информационный характер</p>
                </div>
            </nav>
            <div class="header__socials">
                <a
                    :href="`tel:${phone.unmasked}`"
                    class="header__social header__social_call"
                    @click="onCall"
                >
                    <!-- call -->
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.97477 8.44334C9.97477 8.63 10.1248 8.77667 10.3081 8.77667C10.6881 8.77667 10.9948 9.08667 10.9948 9.46334C10.9948 9.64667 11.1448 9.79667 11.3281 9.79667C11.5114 9.79667 11.6614 9.64667 11.6614 9.46334C11.6614 8.71667 11.0548 8.11 10.3081 8.11C10.1248 8.11 9.97477 8.26001 9.97477 8.44334ZM15.4014 9.79667C15.5881 9.79667 15.7348 9.64667 15.7348 9.46334C15.7348 6.47001 13.3014 4.03668 10.3081 4.03668C10.1248 4.03668 9.97477 4.18667 9.97477 4.37002C9.97477 4.55334 10.1248 4.70335 10.3081 4.70335C12.9348 4.70335 15.0681 6.84001 15.0681 9.46334C15.0681 9.64667 15.2181 9.79667 15.4014 9.79667Z" fill="#ABABAB"/>
                        <path d="M13.0314 9.46334C13.0314 9.64667 13.1814 9.79667 13.3648 9.79667C13.5481 9.79667 13.6981 9.64667 13.6981 9.46334C13.6981 7.59334 12.1781 6.07334 10.3081 6.07334C10.1248 6.07334 9.97477 6.22335 9.97477 6.40668C9.97477 6.59334 10.1248 6.74001 10.3081 6.74001C11.8114 6.74001 13.0314 7.96334 13.0314 9.46334ZM10.3081 2C10.1248 2 9.97477 2.15 9.97477 2.33333C9.97477 2.51668 10.1248 2.66667 10.3081 2.66667C14.0581 2.66667 17.1081 5.71667 17.1081 9.46334C17.1081 9.64667 17.2548 9.79667 17.4414 9.79667C17.6248 9.79667 17.7748 9.64667 17.7748 9.46334C17.7748 5.34668 14.4248 2 10.3081 2ZM13.5648 12.9767L13.1281 13.2133C12.3914 13.6167 11.4581 13.48 10.8614 12.8833L7.34143 9.36334C6.74477 8.76668 6.60811 7.83333 7.01143 7.09334L7.24811 6.66001C7.51477 6.16667 7.61143 5.61334 7.53143 5.06C7.45477 4.50667 7.20477 4.00334 6.80477 3.60333C6.31811 3.11668 5.67145 2.85002 4.98145 2.85002C4.29144 2.85002 3.64144 3.11668 3.15477 3.60333L2.98478 3.77667C2.08478 4.67668 1.98145 6.22666 2.69145 8.14667C3.36143 9.98 4.71145 11.9667 6.48478 13.74C9.18811 16.4433 12.1981 18 14.3581 18C15.2114 18 15.9348 17.7567 16.4481 17.2433L16.6214 17.07C17.1048 16.5867 17.3748 15.94 17.3781 15.2433C17.3748 14.5633 17.0981 13.8967 16.6214 13.42C15.8181 12.6167 14.5614 12.4367 13.5648 12.9767Z" fill="#ABABAB"/>
                    </svg>
                    <span class="sr-only">Позвонить нам</span>
                </a>
                <a
                    href="https://ru.pinterest.com/DeClubDesign/?invite_code=d02e8b91ac8548539335db847298483e&sender=728598183373966788"
                    class="header__social"
                    target="_blank"
                >
                    <!-- pinterest -->
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.3577 2.85715C6.21536 2.85715 2.85715 6.21502 2.85715 10.3565C2.85715 13.4283 4.70359 16.0665 7.34676 17.2263C7.32439 16.7022 7.34186 16.0742 7.47611 15.5046C7.6211 14.8954 8.44218 11.4171 8.44218 11.4171C8.44218 11.4171 8.20286 10.9382 8.20286 10.2309C8.20286 9.11923 8.84687 8.28935 9.64844 8.28935C10.3314 8.28935 10.6602 8.80206 10.6602 9.4152C10.6602 10.1006 10.2234 11.127 9.99868 12.0767C9.81094 12.8725 10.3975 13.5217 11.1816 13.5217C12.6029 13.5217 13.5602 11.6958 13.5602 9.53389C13.5602 7.89048 12.4531 6.66005 10.4394 6.66005C8.16391 6.66005 6.74747 8.35648 6.74747 10.2514C6.74747 10.9051 6.93914 11.3655 7.24169 11.7221C7.37987 11.8865 7.39931 11.9527 7.34873 12.1404C7.31272 12.2786 7.23098 12.6108 7.19597 12.7421C7.14629 12.9324 6.99263 13 6.82046 12.9304C5.77276 12.5018 5.28534 11.3553 5.28534 10.0651C5.28534 7.93423 7.08216 5.37999 10.6447 5.37999C13.5086 5.37999 15.394 7.45148 15.394 9.67588C15.394 12.6181 13.7577 14.8165 11.3461 14.8165C10.5366 14.8165 9.77492 14.3782 9.51419 13.8811C9.51419 13.8811 9.07839 15.6097 8.98699 15.9428C8.82743 16.5207 8.51611 17.0993 8.232 17.5498C8.90622 17.7487 9.61927 17.8571 10.3577 17.8571C14.4999 17.8571 17.8571 14.4993 17.8571 10.3564C17.8571 6.21502 14.4999 2.85715 10.3577 2.85715Z" fill="#ABABAB"/>
                    </svg>
                    <span class="sr-only">Наш pinterest</span>
                </a>
                <a
                    href="https://www.houzz.ru/professionaly/dizaynery-i-razrabotchiki-v-sfere-stroitelystva/de-club-pfvwru-pf~27367663"
                    class="header__social"
                    target="_blank"
                >
                    <!-- houzz -->
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.57143 2.14286V17.1429H8.60268V12.4179H11.9527V17.1429H16.9839V8.62411L6.90893 5.73661V2.14286H3.57143Z" fill="#ABABAB"/>
                    </svg>
                    <span class="sr-only">Наш houzz</span>
                </a>
            </div>
            <button
                :class="['header__burger', { 'header__burger_active': isShowNav }]"
                @click="isShowNav = !isShowNav"
                aria-label="выпадающее меню"
            >
                <span class="header__burger-line"></span>
                <span class="header__burger-line"></span>
            </button>
        </div>
    </header>
</template>

<script>
import { CALLBACK_MODAL } from '@/store/modalConstants';
import sendAnalytics from '@/sendAnalytics';
import { mapState } from 'vuex';
import HeaderLogo from './HeaderLogo.vue';

export default {
  name: 'TheHeader',
  components: {
    HeaderLogo,
  },

  data() {
    return {
      isShowNav: false,
      isScroll: false,
    };
  },

  computed: {
    ...mapState('header', ['menu']),
    ...mapState('settings', ['phone']),
    windowWidth() {
      return this.$store.state.dom.windowWidth;
    },
  },

  methods: {
    onScroll() {
      this.isScroll = window.scrollY > 0;
    },

    openCallbackModal() {
      this.$store.commit('modal/openModal', {
        modal: CALLBACK_MODAL,
        label: 'zakaz_zvonka_header',
        analytics: true,
      });
    },

    onClickNavItem(label) {
      this.isShowNav = false;
      const elem = document.getElementById(label.slice(1));
      window.scrollTo({
        top: window.scrollY + elem.getBoundingClientRect().top - 65,
        // behavior: 'smooth'
      });

      sendAnalytics('navigation', 'click', label);
    },

    onCall() {
      sendAnalytics('navigation', 'click', 'call');
    },
  },

  mounted() {
    this.onScroll();
    window.addEventListener('scroll', this.onScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
@import './header.scss';
</style>
