import { createStore } from 'vuex';

import data from './modules/data';
import settings from './modules/settings';
import dom from './modules/dom';
import calculator from './modules/calculator';
import modal from './modules/modal';
import modalFrame from './modules/modalFrame';

export default createStore({
  modules: {
    data,
    settings,
    dom,
    calculator,
    modal,
    modalFrame,
  },
});
