<template>
    <div class="modal__content">
        <div class="modal__body modal__body_thanks">
            <h2 class="modal__title">Спасибо за заявку!</h2>
            <p class="modal__text">Нам нужно уточнить еще несколько деталей. Мы свяжемся с вами и проконсультируем по всем вопросам.</p>
            <p class="modal__text">Окончательную стоимость проекта и о каждом этапе подробно можно узнать на консультации с арт-директором, где мы:</p>
            <ul class="ui-list ui-list_line modal__list">
                <li>презентуем наш подход;</li>
                <li>подготовим для вас несколько вариантов планировок;</li>
                <li>ответим на все вопросы.</li>
            </ul>
            <p
                class="modal__subtitle"
                v-if="mode === 'calc'"
            >Дизайн-проект квартиры {{ area }} м<sup>2</sup> включает в себя:</p>
            <ul
                class="ui-list ui-list_checkmark modal__list"
                v-if="mode === 'calc'"
            >
                <li><strong>Эскизный проект</strong> – от 150 часов работы архитектора. Индивидуальное планировочное решение, подбор стилистики проекта и материалов.</li>
                <li><strong>Рабочий проект</strong> – от 150 часов работы архитектора. Техническое задание для строителей (планы расстановки сантехники, электрики, раскладки плитки, полов, потолка).</li>
                <li><strong>Ультрареалистичная ЗD визуализация</strong> – от 40 часов работы визуализатора.</li>
                <li><strong>Сопровождение проекта</strong> – от 40 часов работы куратора. Контроль документооборота, координация служб, подготовка отчетности.от 40 часов работы визуализатора.</li>
                <li><strong>Расчет ремонтных работ</strong> – подробная смета. Стоимость работ зависит от ваших пожеланий, ее невозможно рассчитать точно до составления дизайн-проекта.</li>
            </ul>
            <picture class="modal__pic">
                <source media="(min-width: 768px)" :srcset="require('@/assets/img/modals/thanks-desktop.jpg')">
                <VLazyImage
                    :src="require('@/assets/img/modals/thanks-mobile.jpg')"
                    alt="Спасибо за заявку!"
                />
            </picture>
            <p class="modal__text">А пока посмотрите на нашу жизнь в социальных сетях :)</p>
            <div class="modal__socials">
                <!-- pinterest -->
                <a
                    href="https://www.pinterest.ru/Declubru/_created/"
                    class="modal__social-btn"
                    target="_blank"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.3577 2.85718C6.21539 2.85718 2.85718 6.21505 2.85718 10.3565C2.85718 13.4283 4.70362 16.0665 7.34679 17.2264C7.32442 16.7022 7.34189 16.0742 7.47614 15.5046C7.62113 14.8954 8.44221 11.4172 8.44221 11.4172C8.44221 11.4172 8.20289 10.9383 8.20289 10.231C8.20289 9.11926 8.8469 8.28938 9.64847 8.28938C10.3314 8.28938 10.6602 8.80209 10.6602 9.41523C10.6602 10.1006 10.2235 11.127 9.99871 12.0767C9.81097 12.8725 10.3976 13.5217 11.1817 13.5217C12.603 13.5217 13.5603 11.6958 13.5603 9.53391C13.5603 7.89051 12.4531 6.66007 10.4394 6.66007C8.16394 6.66007 6.7475 8.3565 6.7475 10.2514C6.7475 10.9052 6.93917 11.3656 7.24172 11.7221C7.3799 11.8865 7.39934 11.9527 7.34876 12.1405C7.31275 12.2786 7.23102 12.6108 7.196 12.7421C7.14632 12.9324 6.99266 13 6.82049 12.9304C5.77279 12.5018 5.28537 11.3553 5.28537 10.0651C5.28537 7.93426 7.08219 5.38002 10.6447 5.38002C13.5086 5.38002 15.394 7.45151 15.394 9.67591C15.394 12.6181 13.7577 14.8166 11.3461 14.8166C10.5367 14.8166 9.77495 14.3782 9.51422 13.8811C9.51422 13.8811 9.07842 15.6097 8.98702 15.9429C8.82746 16.5207 8.51614 17.0994 8.23203 17.5498C8.90625 17.7487 9.6193 17.8572 10.3577 17.8572C14.5 17.8572 17.8572 14.4994 17.8572 10.3564C17.8572 6.21505 14.5 2.85718 10.3577 2.85718Z" fill="#D3A264"/>
                    </svg>
                    <span class="sr-only">pinterest</span>
                </a>
                <!-- houzz -->
                <a
                    href="https://www.houzz.ru/professionaly/dizaynery-i-razrabotchiki-v-sfere-stroitelystva/de-club-pfvwru-pf~27367663"
                    class="modal__social-btn"
                    target="_blank"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.57141 2.14288V17.1429H8.60266V12.4179H11.9527V17.1429H16.9839V8.62413L6.90891 5.73663V2.14288H3.57141Z" fill="#D3A264"/>
                    </svg>
                    <span class="sr-only">houzz</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'ModalThanks',
  props: {
    mode: String,
    area: Number,
  },
  components: {
    VLazyImage,
  },
};
</script>
