<template>
    <div class="section-cards">
        <div
            class="swiper"
            ref="carousel"
        >
            <div class="swiper-wrapper">
                <div
                    v-for="(item, index) in slides"
                    :key="index"
                    class="swiper-slide section-cards__item"
                >
                    <VLazyImage
                        class="section-cards__picture"
                        :src="item.content_item_pic"
                        alt=""
                    />
                    <p class="section-cards__title">{{item.content_item_title}}</p>
                    <p class="section-cards__desc">{{item.content_item_desc}}</p>
                </div>
            </div>
        </div>
        <div class="swiper-button swiper-button-prev" ref="nav_prev">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.64645 9.35352L0.292894 4.99996L4.64645 0.646408L5.35355 1.35352L1.70711 4.99996L5.35355 8.64641L4.64645 9.35352Z"
                    :fill="svgColorFill"
                />
            </svg>
        </div>
        <div class="swiper-button swiper-button-next" ref="nav_next">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.35356 0.646484L5.70711 5.00004L1.35356 9.35359L0.646454 8.64648L4.2929 5.00004L0.646454 1.35359L1.35356 0.646484Z"
                    :fill="svgColorFill"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';
import Swiper, { Navigation } from 'swiper';

export default {
  name: 'UiSectionCards',
  props: {
    slides: Array,
    theme: {
      type: String,
      default: 'light',
    },
  },

  components: {
    VLazyImage,
  },

  computed: {
    svgColorFill() {
      return this.theme === 'light' ? '#202020' : '#ffffff';
    },
  },

  mounted() {
    this.swiper = new Swiper(this.$refs.carousel, {
      modules: [Navigation],
      spaceBetween: 45,

      navigation: {
        nextEl: this.$refs.nav_next,
        prevEl: this.$refs.nav_prev,
      },

      breakpoints: {
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
        },

        900: {
          slidesPerView: 3,
        },
      },
    });
  },

  beforeUnmount() {
    this.swiper.destroy(true, true);
  },
};
</script>

<style lang="scss">
@import './section-cards.scss';
</style>
