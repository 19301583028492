export default {
  namespaced: true,
  state: {
    title: '',
    team: [],
  },
  mutations: {
    setData(state, payload) {
      state.title = payload.data.title;
      state.team = payload.data.items.map((id) => payload.team[id]);
    },
  },
};
