<template>
    <section class="education">
        <div class="content">
            <h2
                class="section-title section-title_large"
                v-html="title"
            ></h2>
            <UiSectionCards
                :slides="content"
                theme="light"
            />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import UiSectionCards from '@/elements/UiSectionCards/UiSectionCards.vue';

export default {
  name: 'BlockEducation',
  components: {
    UiSectionCards,
  },
  computed: {
    ...mapState('education', ['title', 'content']),
  },
};
</script>

<style lang="scss">
@import './education.scss';
</style>
