<template>
  <div
    class="swiper carousel"
    ref="carousel"
  >
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in slides"
        :key="index"
      >
        <picture
          class="carousel__picture"
          v-if="slideType === 'images'"
        >
          <!-- <source media="(min-width: 600px)" :srcset="item.full"> -->
          <VLazyImage :src="item.full" alt="" />
        </picture>

          <picture
            class="carousel__picture carousel__picture_clickable"
            v-if="slideType === 'videos'"
            @click="openVideoModal(item.video_url, index)"
          >
            <source media="(min-width: 600px)" :srcset="item.image.full">
            <VLazyImage :src="item.image.mobile" alt="" />
          </picture>
          <div
            class="play-btn"
            @click="openVideoModal(item.video_url, index)"
            v-if="slideType === 'videos'"
          >
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384613 0.992676L6.30769 7.77998L0.384613 14.5673V0.992676ZM1.29307 3.46324V12.0967L5.06016 7.77998L1.29307 3.46324Z" fill="#9E162F"/>
            </svg>
          </div>
      </div>
    </div>
    <div
      class="carousel__pagination"
      ref="pagination"
    ></div>
    <div class="swiper-button swiper-button-prev" ref="nav_prev">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.64645 9.35352L0.292894 4.99996L4.64645 0.646408L5.35355 1.35352L1.70711 4.99996L5.35355 8.64641L4.64645 9.35352Z"
          fill="#9E162F"
        />
      </svg>
    </div>
    <div class="swiper-button swiper-button-next" ref="nav_next">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.35356 0.646484L5.70711 5.00004L1.35356 9.35359L0.646454 8.64648L4.2929 5.00004L0.646454 1.35359L1.35356 0.646484Z"
          fill="#9E162F"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { YOUTUBE_MODAL } from '@/store/modalConstants';
import Swiper, { Pagination, Navigation } from 'swiper';
import VLazyImage from 'v-lazy-image';

export default {
  name: 'UiCarousel',

  props: {
    slides: Object,
    slideType: {
      type: String,
    },
  },

  components: {
    VLazyImage,
  },

  methods: {
    openVideoModal(video, index) {
      this.$store.commit('modalFrame/openFrame', {
        frame: video,
        label: YOUTUBE_MODAL,
        name: `video-${index + 1}`,
        analytics: true,
      });
    },
  },

  mounted() {
    this.swiper = new Swiper(this.$refs.carousel, {
      modules: [Pagination, Navigation],
      rewind: true,
      pagination: {
        el: this.$refs.pagination,
        type: 'fraction',
      },
      navigation: {
        nextEl: this.$refs.nav_next,
        prevEl: this.$refs.nav_prev,
      },
    });
  },

  beforeUnmount() {
    this.swiper.destroy(true, true);
  },
};
</script>

<style lang="scss">
@import './carousel.scss';
</style>
