<template>
  <div class="ab__comparison comparison">
    <div class="comparison__info comparison__info_left">визуал</div>
    <div class="comparison__info comparison__info_right">ремонт</div>
    <div class="comparison__wrapper" ref="wrapper">
      <picture class="comparison__image">
        <source media="(min-width: 1200px)" :srcset="item.after.desktop">
        <img
          :src="item.after.tablet"
          @load="onFirstImgLoad"
          loading="lazy"
          alt=""
        >
      </picture>
      <picture
        class="comparison__image comparison__image_overlay"
        ref="overlay"
        :style="{ clip: 'rect(0px,' + position + 'px,' + height + 'px, 0px)' }"
      >
        <source media="(min-width: 1200px)" :srcset="item.before.desktop">
        <img
          :src="item.before.tablet"
          @load="onSecondImgLoad"
          loading="lazy"
          alt=""
        >
      </picture>
      <div
        class="comparison__line"
        ref="line"
        :style="{left: `${position}px`}"
      ></div>
      <div
        class="comparison__runner"
        ref="runner"
        :style="{left: `${position}px`}"
      ></div>
    </div>
    <BlurLoadScreen v-if="!isLoaded.all" />
  </div>
</template>

<script>
import BlurLoadScreen from '@/elements/BlurLoadScreen/BlurLoadScreen.vue';

export default {
  name: 'BlockComparison',

  props: {
    item: Object,
  },

  components: {
    BlurLoadScreen,
  },

  emits: ['loaded'],

  data() {
    return {
      click: false,
      precent: 50,
      position: 0,
      width: 0,
      height: 0,
      isLoaded: {
        all: false,
        first: false,
        second: false,
      },
    };
  },

  mounted() {
    this.$refs.runner.addEventListener('mousedown', this.touchReady);
    this.$refs.runner.addEventListener('touchstart', this.touchReady);

    window.addEventListener('mouseup', this.touchFinish);
    window.addEventListener('touchend', this.touchFinish);

    window.addEventListener('mousemove', this.runnerMove);
    window.addEventListener('touchmove', this.runnerMove);

    window.addEventListener('resize', this.onResize);

    this.onResize();
  },

  methods: {
    touchReady(event) {
      if (typeof event.cancelable !== 'boolean' || event.cancelable) {
        event.preventDefault();
        this.click = true;
      }
    },

    touchFinish() {
      this.click = false;
    },

    runnerMove(e) {
      if (this.click) {
        let pos = this.getCursorPos(e);
        if (pos < 0) {
          pos = 0;
        }
        if (pos > this.width) {
          pos = this.width;
        }
        this.precent = pos / (this.width / 100);
        this.position = pos;
      }
    },

    getCursorPos(e) {
      // getCursorPos выводит координату X относительно элемента wrapper
      // нужно для определения типа event: e.changedTouches – для касания пальцем, e - для мышки
      const event = (e.changedTouches) ? e.changedTouches[0] : e;

      const a = this.$refs.wrapper.getBoundingClientRect();
      let x = event.pageX - a.left;
      x -= window.pageXOffset;
      return x;
    },

    onResize() {
      this.width = this.$refs.wrapper.offsetWidth;
      this.height = this.$refs.wrapper.offsetHeight;
      this.position = this.precentToPx();
    },

    precentToPx() {
      return this.precent * (this.width / 100);
    },

    onImgLoad() {
      if (this.isLoaded.first && this.isLoaded.second) {
        this.onResize();
        this.$emit('loaded');
        this.isLoaded.all = true;
      }
    },

    onFirstImgLoad() {
      this.isLoaded.first = true;
      this.onImgLoad();
    },

    onSecondImgLoad() {
      this.isLoaded.second = true;
      this.onImgLoad();
    },

    reset() {
      this.isLoaded.first = false;
      this.isLoaded.second = false;
      this.isLoaded.all = false;
      this.precent = 50;
    },
  },

  beforeUnmount() {
    this.$refs.runner.removeEventListener('mousedown', this.touchReady);
    this.$refs.runner.removeEventListener('touchstart', this.touchReady);

    window.removeEventListener('mouseup', this.touchFinish);
    window.removeEventListener('touchend', this.touchFinish);

    window.removeEventListener('mousemove', this.runnerMove);
    window.removeEventListener('touchmove', this.runnerMove);

    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss">
@import './comparison.scss';
</style>
