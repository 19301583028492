<template>
  <div
    :class="['form-input', `form-input_${theme}`]"
    ref="root"
  >
    <label
      :for="inputId"
      class="form-input__label"
    >Ваш номер телефона</label>
    <input
      :id="inputId"
      type="tel"
      inputmode="numeric"
      name="telefon_lida"
      :class="[
        'form-input__input',
        {'form-input__input_error': isError},
        {'form-input__input_success': isValid}
      ]"
      :placeholder="placeholder"
      v-model="inputValue"
      ref="inputPhone"
      @blur="onBlur"
      @focus="onFocus"
      autocomplete="off"
    >
    <!-- <p class="form-input__error">некорректный номер</p> -->
  </div>
</template>

<script>
const getInputmask = () => import(/* webpackChunkName: "inputmask" */ 'inputmask');

export default {
  props: {
    theme: {
      type: String,
      default: 'light',
      validator: (val) => ['light', 'dark'].indexOf(val) !== -1,
    },

    inputId: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: '+7 (___) ___-__-__',
    },
  },

  emits: ['onChange', 'onValid'],

  data() {
    return {
      inputValue: '',
      isValid: false,
      isError: false,
    };
  },

  watch: {
    inputValue() {
      if (this.mask) {
        this.isValid = this.mask.unmaskedvalue().length === 10;

        this.$emit('onChange', this.inputValue);
        this.$emit('onValid', this.isValid);
      }
    },
  },

  mounted() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.maskInit();
          this.observer.disconnect();
        }
      }, {
        threshold: 0,
      });
      this.observer.observe(this.$refs.root);
    } else {
      this.maskInit();
    }
  },

  methods: {
    maskInit() {
      getInputmask().then(({ default: Inputmask }) => {
        this.mask = new Inputmask({
          mask: '+7 (q99) 999-99-99',
          definitions: {
            q: {
              validator: '[1,2,3,4,5,6,9]',
              cardinality: 1,
            },
          },
        }).mask(this.$refs.inputPhone);
      });
    },

    onBlur() {
      if (this.mask) {
        const valueLength = this.mask.unmaskedvalue().length;
        if (valueLength > 0 && valueLength < 10) {
          this.onError();
        }
      }
    },

    onFocus() {
      this.isError = false;
    },

    clearInput() {
      this.inputValue = '';
    },

    setFocus() {
      this.$refs.inputPhone.focus();
    },

    onError() {
      this.isError = true;
    },
  },

  beforeUnmount() {
    if ('IntersectionObserver' in window && this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss">
@import 'form-input.scss';
</style>
