export const CALC_MODAL = 'calc-modal';
export const CALLBACK_MODAL = 'callback-modal'; // => default modal

export const THANKS_MODAL = 'thanks-modal';
export const THANKS_CALC_MODAL = 'thanks-calc-modal';

export const POLITICS_MODAL = 'politics-modal';

export const YOUTUBE_MODAL = 'youtube-modal';
export const TOUR_MODAL = 'tour-modal';
