<template>
    <section class="ab">
        <div class="content">
            <h2 class="section-title" v-html="title"></h2>
            <div class="ab__wrapper">
                <BlockComparison
                    :item="currentObj"
                    ref="comparison"
                    @loaded="imgLoaded"
                />
                <div class="ab__content">
                    <div class="ab__info">
                        <div
                            class="ab__pagination"
                            ref="abpag"
                            v-html="pagInfo"
                        ></div>
                        <p class="ab__title">{{currentObj.title}}</p>
                        <p class="ab__area">площадь: <span>{{currentObj.area}} м<sup>2</sup></span></p>
                        <p class="ab__time">срок реализации: <span>{{currentObj.time}}</span></p>
                    </div>
                    <div class="ab__carousel">
                        <div class="swiper" ref="slider">
                            <div class="swiper-wrapper">
                                <div
                                    v-for="(item, index) in content"
                                    :key="index"
                                    @click="setCurItem(index)"
                                    :class="[
                                        'swiper-slide',
                                        'ab__slide',
                                        { 'ab__slide_active' : index === activeIndex },
                                        { 'ab__slide_deactive': isLoaded && index !== activeIndex }
                                    ]"
                                >
                                    <VLazyImage
                                        class="ab__picture"
                                        :src="item.item_visual.sizes.mobile"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            :class="[
                                'swiper-button',
                                'swiper-button-prev',
                                { 'swiper-button-remove' : isLoaded }
                            ]"
                            ref="nav_prev"
                        >
                            <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="6.56921" y="0.774414" width="1.09486" height="10.2187" transform="rotate(-45 6.56921 0.774414)" fill="#ABABAB"/>
                                <rect x="7.22571" width="1.09486" height="10.2187" transform="rotate(45 7.22571 0)" fill="#ABABAB"/>
                            </svg>
                        </div>
                        <div
                            :class="[
                                'swiper-button',
                                'swiper-button-next',
                                { 'swiper-button-remove' : isLoaded }
                            ]"
                            ref="nav_next"
                        >
                            <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="7.99915" y="7.22559" width="1.09486" height="10.2187" transform="rotate(135 7.99915 7.22559)" fill="#ABABAB"/>
                                <rect x="7.34265" y="8" width="1.09486" height="10.2187" transform="rotate(-135 7.34265 8)" fill="#ABABAB"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import sendAnalytics from '@/sendAnalytics';
import Swiper, { Navigation } from 'swiper';
import { mapState } from 'vuex';
import VLazyImage from 'v-lazy-image';
import BlockComparison from './BlockComparison.vue';

export default {
  name: 'BlockAfterBefore',

  components: {
    BlockComparison,
    VLazyImage,
  },

  data() {
    return {
      activeIndex: 0,
      isLoaded: true,
    };
  },

  computed: {
    ...mapState('afterbefore', ['title', 'content', 'currentObj']),
    pagInfo() {
      return `${this.activeIndex + 1} / <span>${this.content.length}</span>`;
    },
  },

  mounted() {
    this.swiper = new Swiper(this.$refs.slider, {
      modules: [Navigation],

      navigation: {
        nextEl: this.$refs.nav_next,
        prevEl: this.$refs.nav_prev,
      },

      slidesPerView: 3,
      spaceBetween: 6,
      breakpoints: {
        900: {
          direction: 'vertical',
          slidesPerView: 2,
          spaceBetween: 10,
        },

        1400: {
          direction: 'vertical',
          slidesPerView: 3,
        },
      },
    });
    this.swiper.disable();
  },

  methods: {
    setCurItem(index) {
      if (!this.isLoaded && this.activeIndex !== index) {
        this.activeIndex = index;
        this.$store.commit('afterbefore/setCurrentItem', index);

        this.swiper.disable();
        this.isLoaded = true;

        this.$refs.comparison.reset();
        sendAnalytics('navigation', 'click', `image${index + 1}`);
      }
    },

    imgLoaded() {
      this.isLoaded = false;
      this.swiper.enable();
    },
  },

  beforeUnmount() {
    this.swiper.destroy(true, true);
  },
};
</script>

<style lang="scss">
@import './afterbefore.scss';
</style>
