export default {
  namespaced: true,
  state: {
    title: '',
    picMob: '',
    picDesk: '',
    address: '',
    station: '',
  },
  mutations: {
    setData(state, data) {
      state.title = data.title;
      state.picMob = data.image_mob;
      state.picDesk = data.image_desk;
      state.address = data.address;
      state.station = data.station;
    },
  },
};
