<template>
    <section class="media">
        <div class="content">
            <h2
                class="section-title"
                id="media"
            >{{title}}</h2>
            <ul class="media_list d-flex flex-wrap justify-content-between">
                <li v-for="(item, index) in content" :key="index" class="media_list__item">
                    <picture class="media_list__item-pic">
                        <VLazyImage :src="item.item_pic" alt="" />
                    </picture>
                    <p class="media_list__item-number">{{item.item_number}}</p>
                    <p class="media_list__item-month">{{item.item_month}}</p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import VLazyImage from 'v-lazy-image';

export default {
  name: 'BlockMedia',
  components: {
    VLazyImage,
  },
  computed: {
    ...mapState('media', ['title', 'content']),
  },
};
</script>

<style lang="scss">
@import './media.scss';
</style>
