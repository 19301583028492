<template>
  <div class="load-screen">
    <SvgPreloader
      width="100px"
      height="100px"
    />
  </div>
</template>

<script>
import SvgPreloader from '@/elements/SvgIcons/SvgPreloader.vue';

export default {
  name: 'TheLoadScreen',
  components: {
    SvgPreloader,
  },
};
</script>

<style lang="scss">
  .load-screen {
    position: fixed;
    background: $black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
</style>
