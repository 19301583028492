<template>
    <div class="modal" v-if="isOpen">
        <div
            class="modal__backdrop"
            @click="closeModal"
        ></div>
        <div class="modal__wrapper">
            <button
                class="modal__top-close-btn"
                @click="closeModal"
            >
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.84155" y="0.628399" width="18.6986" height="1.62596" transform="rotate(45 1.84155 0.628399)" fill="#ABABAB"/>
                    <rect x="1.02856" y="13.6361" width="18.6986" height="1.62596" transform="rotate(-45 1.02856 13.6361)" fill="#ABABAB"/>
                </svg>
            </button>
            <ModalForm
                v-if="calcModal"
                :imgDefault="require('@/assets/img/modals/calc-default.jpg')"
                :imgMobile="require('@/assets/img/modals/calc-mobile.jpg')"
                @onSubmit="onSubmit"
                :meta="meta"
                :comment="commentForm"
            >
                <h2 class="modal__title">Добрый день!</h2>
                <p class="modal__text">Оставьте контакты и мы рассчитаем стоимость вашего дизайн-проекта и ремонта</p>
            </ModalForm>
            <ModalForm
                v-if="callbackModal"
                :imgDefault="require('@/assets/img/modals/form-default.jpg')"
                :imgMobile="require('@/assets/img/modals/form-mobile.jpg')"
                @onSubmit="onSubmit"
                :meta="meta"
            >
                <h2 class="modal__title">Добрый день!</h2>
                <p class="modal__text">Оставьте контакты, мы перезвоним и</p>
                <ul class="ui-list ui-list_line modal__list">
                    <li>ответим на все ваши вопросы;</li>
                    <li>расскажем из чего состоит проект и как мы работаем;</li>
                    <li>поможем определиться со стилем и нужными чертежами.</li>
                </ul>
            </ModalForm>
            <ModalThanks
                v-if="thanksModal"
            />
            <ModalThanks
                v-if="thanksCalcModal"
                mode="calc"
                :area="square"
            />
            <ModalPolitics
                v-if="politicsModal"
            />
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { CALC_MODAL, CALLBACK_MODAL, THANKS_MODAL, THANKS_CALC_MODAL, POLITICS_MODAL } from '@/store/modalConstants';
import { mapState } from 'vuex';

import submit from '@/submitForm';
import sendAnalytics from '@/sendAnalytics';

import ModalForm from './ModalForm.vue';
import ModalThanks from './ModalThanks.vue';
import ModalPolitics from './ModalPolitics.vue';

const realtyType = {
  flat: 'Квартиры',
  house: 'Загородного дома',
  office: 'Офиса',
  townhouse: 'Таунхауса',
};

export default {
  name: 'TheModal',

  components: {
    ModalForm,
    ModalThanks,
    ModalPolitics,
  },

  computed: {
    ...mapState('modal', ['isOpen', 'openModal', 'meta']),

    ...mapState('calculator', ['type', 'work', 'square', 'you_val', 'we_val']),

    calcModal() {
      return this.openModal === CALC_MODAL;
    },

    callbackModal() {
      return this.openModal === CALLBACK_MODAL;
    },

    thanksModal() {
      return this.openModal === THANKS_MODAL;
    },

    thanksCalcModal() {
      return this.openModal === THANKS_CALC_MODAL;
    },

    politicsModal() {
      return this.openModal === POLITICS_MODAL;
    },

    commentForm() {
      return `
          Клиент ввел в калькулятор следующие данные:
          – площадь объекта: ${this.square} квадратных метра
          – тип объекта: ${realtyType[this.type]}
          – нужен ли ремонт: ${this.work === 1 ? 'только дизайн' : 'дизайн + ремонт'}
          – время создания проекта: вы - ${this.you_val}ч, мы - ${this.we_val}ч
      `;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/closeModal');
    },

    onSubmit(event) {
      submit(event).then((res) => {
        if (res) {
          sendAnalytics('lead_form', 'send', this.meta, 'lead_form');
          const thanks = this.openModal === CALC_MODAL ? THANKS_CALC_MODAL : THANKS_MODAL;
          this.$store.commit('modal/openModal', {
            modal: thanks,
            label: thanks,
            analytics: false,
          });
        }
      }).catch((err) => {
        console.error(err);
      });
    },
  },
};
</script>

<style lang="scss">
@import './modal.scss';
</style>
