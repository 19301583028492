export default {
  namespaced: true,
  state: {
    title: '',
    subtitle: '',
    images: [],
  },
  mutations: {
    setData(state, payload) {
      state.title = payload.data.title;
      state.subtitle = payload.data.subtitle;
      state.images = payload.images;
    },
  },
};
