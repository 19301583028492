<template>
  <div class="custom-range">
      <!-- <input
        ref="input"
        type="range"
        min="0"
        max="100"
        value="50"
        id="widget1"
        step="1"
      > -->
      <div class="custom-range__track" aria-hidden="true" ref="root">
          <span class="custom-range__background"></span>
          <span
            class="custom-range__fill"
            :style="{width: precent + '%'}"
            ref="fill"
          ></span>
          <span
            class="custom-range__thumb"
            :style="{left: precent + '%'}"
            ref="thumb"
            @mousedown="touchReady"
            @touchstart="touchReady"
          ></span>
      </div>
      <!-- <output for="widget1" aria-hidden="true" ref="output">50</output> -->
  </div>
</template>

<script>
export default {
  name: 'UiCustomRange',

  props: {
    min: Number,
    max: Number,
    value: Number,
  },

  emits: [
    'setSquare',
  ],

  data() {
    return {
      click: false,
      precent: 0,
    };
  },

  watch: {
    value() {
      this.precent = this.value / (this.max / 100);
    },
  },

  mounted() {
    window.addEventListener('mouseup', this.touchFinish);
    window.addEventListener('touchend', this.touchFinish);

    window.addEventListener('mousemove', this.runnerMove);
    window.addEventListener('touchmove', this.runnerMove);

    this.precent = this.value / (this.max / 100);
  },

  methods: {
    touchReady(event) {
      if (typeof event.cancelable !== 'boolean' || event.cancelable) {
        event.preventDefault();
        this.click = true;
      }
    },

    touchFinish() {
      this.click = false;
    },

    runnerMove(e) {
      if (this.click) {
        const width = this.$refs.root.offsetWidth;

        let pos = this.getCursorPos(e);
        if (pos < 0) {
          pos = 0;
        }
        if (pos > width) {
          pos = width;
        }
        this.precent = pos / (width / 100);

        this.$emit('setSquare', Math.round(this.precent * (this.max / 100)));
      }
    },

    getCursorPos(e) {
      // getCursorPos выводит координату X относительно элемента wrapper
      // нужно для определения типа event: e.changedTouches – для касания пальцем, e - для мышки
      const event = (e.changedTouches) ? e.changedTouches[0] : e;

      const a = this.$refs.root.getBoundingClientRect();
      let x = event.pageX - a.left;
      x -= window.pageXOffset;
      return x;
    },
  },

  beforeUnmount() {
    window.removeEventListener('mouseup', this.touchFinish);
    window.removeEventListener('touchend', this.touchFinish);

    window.removeEventListener('mousemove', this.runnerMove);
    window.removeEventListener('touchmove', this.runnerMove);

    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss">
  .custom-range {
    margin-top: 15px;
    padding: 0 7px;

    &__track {
      width: 100%;
      height: 4px;
      position: relative;
    }

    &__background {
      background: #ABABAB;
      position: absolute;
      top: 0;
      right: -7px;
      bottom: 0;
      left: -7px;
    }

    &__fill {
      background: #D3A264;
      height: 100%;
      display: block;
      margin: 0 -7px;
      position: relative;
      z-index: 2;
    }

    &__thumb {
      // border: 1px solid blueviolet;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;

      &::before {
        background: #D3A264;
        box-shadow: 0px 0px 10px #473A2A;
        border-radius: 50%;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media (min-width: 1024px) {
    .custom-range {
      margin-top: 22px;
    }
  }
</style>
