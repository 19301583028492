<template>
  <button
    class="form-button btn"
    :disabled="!disabled"
    type="submit"
  >{{ title }}</button>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    title: String,
    disabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import './form-button.scss';
</style>
