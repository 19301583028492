<template>
  <div class="modal__content">
    <div class="modal__body">
      <slot></slot>
      <form
        action="/mail-new.php"
        class="modal__form"
        @submit.prevent="onSubmit"
      >
        <div class="modal__inputs">
          <input type="hidden" name="site_form_id" :value="meta">
          <input
            v-if="comment"
            type="hidden"
            name="kommentaryi"
            :value="comment"
          >
          <input
            v-for="{name, value}, key in GETParams"
            type="hidden"
            :key="key"
            :name="name"
            :value="value"
          >

          <UiInputName
            class="modal__input"
            inputId="modal_name"
            @onChange="onChangeName"
          />
          <UiInputPhone
            class="modal__input"
            inputId="modal_phone"
            @onChange="onChangePhone"
            @onValid="onValidationPhone"
            ref="inputPhone"
          />
        </div>
        <UiFormButton
          class="modal__send-btn"
          title="Рассчитать"
        />
      </form>
    </div>
    <picture class="modal__pic">
      <source media="(min-width: 768px)" :srcset="imgDefault">
      <VLazyImage :src="imgMobile" alt="" />
    </picture>
  </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';
import UiInputName from '@/elements/UiInputElems/UiInputName.vue';
import UiInputPhone from '@/elements/UiInputElems/UiInputPhone.vue';
import UiFormButton from '@/elements/UiFormButton/UiFormButton.vue';

export default {
  name: 'ModalForm',

  props: {
    imgDefault: String,
    imgMobile: String,
    meta: String,
    comment: String,
  },

  components: {
    UiInputName,
    UiInputPhone,
    UiFormButton,
    VLazyImage,
  },

  data() {
    return {
      valueName: '',
      valuePhone: '',
      phoneValid: false,
    };
  },

  computed: {
    GETParams() {
      return this.$store.state.data.GETParams;
    },
  },

  methods: {
    onChangeName(val) {
      this.valueName = val;
    },

    onChangePhone(val) {
      this.valuePhone = val;
    },

    onValidationPhone(bool) {
      this.phoneValid = bool;
    },

    onSubmit(event) {
      if (this.phoneValid) {
        this.$emit('onSubmit', event);
      } else {
        this.$refs.inputPhone.onError();
      }
    },
  },

  mounted() {
    this.$refs.inputPhone.setFocus();
  },
};
</script>
