export default {
  namespaced: true,
  state: {
    title: '',
    logos: '',
  },
  mutations: {
    setData(state, data) {
      state.title = data.title;
      state.logos = data.items;
    },
  },
};
