<template>
  <div class="blur-load-screen">
    <SvgPreloader width="40px" height="40px"/>
  </div>
</template>

<script>
import SvgPreloader from '@/elements/SvgIcons/SvgPreloader.vue';

export default {
  name: 'BlurLoadScreen',
  components: {
    SvgPreloader,
  },
};
</script>

<style lang="scss">
  .blur-load-screen {
    background: rgb(223, 223, 223);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  }

  @supports (backdrop-filter: blur(30px)) {
    .blur-load-screen {
      background: rgba(32, 32, 32, 0.5);
      backdrop-filter: blur(30px);
    }
  }
</style>
