export default {
  namespaced: true,
  state: {
    title: '',
    content: [],
    currentItem: 0,
    currentObj: {
      title: '',
      area: 0,
      time: '',
      after: {
        desktop: '',
        tablet: '',
        mobile: '',
      },
      before: {
        desktop: '',
        tablet: '',
        mobile: '',
      },
    },
  },
  mutations: {
    setData(state, data) {
      state.title = data.title;

      state.content = data.content;
    },
    setCurrentItem(state, id) {
      state.currentItem = id;

      const currItem = state.content[id];

      state.currentObj.title = currItem.item_title;
      state.currentObj.area = currItem.item_area;
      state.currentObj.time = currItem.item_time;

      state.currentObj.after.desktop = currItem.item_visual.url;
      state.currentObj.after.tablet = currItem.item_visual.sizes.large;
      state.currentObj.after.mobile = currItem.item_visual.sizes.mobile;

      state.currentObj.before.desktop = currItem.item_repair.url;
      state.currentObj.before.tablet = currItem.item_repair.sizes.large;
      state.currentObj.before.mobile = currItem.item_repair.sizes.mobile;
    },
  },
  actions: {
    initData({ commit }, data) {
      commit('setData', data);
      commit('setCurrentItem', 0);
    },
  },
};
