<template>
  <section class="portfolio">
    <div class="content">
      <h2
        class="section-title section-title_large"
        v-html="title"
        id="portfolio"
      ></h2>
      <UiTabList
        v-if="categories"
        tabType="category"
        :itemList="categories"
        :activeCat="activeCat"
        @setActive="setActiveCat"
      />
      <UiTabList
        v-if="categories"
        tabType="object"
        :itemList="objectList"
        :activeObject="activeObject"
        @setActive="setActiveObject"
      />
      <div class="portfolio__wrapper">
        <BlockPortfolioSlider
          v-if="currentObject.pictures"
          class="portfolio__gallery"
          :slides="currentObject.pictures"
          :loading="isLoading"
          @loaded="loaded"
        />
        <article class="portfolio__body">
          <div class="portfolio__article portfolio__article_scroll" ref="article">
            <div class="portfolio__item">
              <p class="portfolio__header portfolio__header_title">
                <span v-html="currentObject.portfolio_title"></span>
              </p>
              <p class="portfolio__text">{{currentObject.portfolio_desc}}</p>
            </div>
            <div class="portfolio__item">
              <p class="portfolio__header portfolio__header_customer">
                <VLazyImage
                  v-if="currentObject.portfolio_customer_photo"
                  :src="currentObject.portfolio_customer_photo"
                  alt="клиент"
                />
                <span>{{currentObject.portfolio_customer_name}}</span>
              </p>
              <p class="portfolio__text portfolio__text_customer">{{currentObject.portfolio_customer_comment}}</p>
            </div>
          </div>
          <button
            class="btn"
            @click="openCallbackModal"
          >Посмотреть проект</button>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { CALLBACK_MODAL } from '@/store/modalConstants';
import { mapState } from 'vuex';
import VLazyImage from 'v-lazy-image';
import UiTabList from '@/elements/UiTabList/UiTabList.vue';
import BlockPortfolioSlider from './BlockPortfolioSlider.vue';

export default {
  name: 'BlockPortfolio',

  components: {
    UiTabList,
    BlockPortfolioSlider,
    VLazyImage,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapState('portfolio', {
      title: 'title',
      categories: 'categories',
      portfolio: 'portfolio',
      objectList: 'objectList',
      activeCat: 'activeCat',
      activeObject: 'activeObject',
      currentObject: 'currentObject',
    }),
  },

  watch: {
    activeObject() {
      this.isLoading = true;
    },
  },

  methods: {
    loaded() {
      this.isLoading = false;
    },

    setActiveCat(id) {
      if (id !== this.activeCat) {
        this.$store.commit('portfolio/setActiveCategory', id);
      }
    },

    setActiveObject(id) {
      if (id !== this.activeObject) {
        this.$store.commit('portfolio/setActiveObject', id);
      }
    },

    openCallbackModal() {
      this.$store.commit('modal/openModal', {
        modal: CALLBACK_MODAL,
        label: 'portfolio_posmotret_proekt',
        analytics: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import './portfolio.scss';
</style>
